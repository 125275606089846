import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { format } from 'date-fns';
import UserLabel from '../UserLabel';

const Username = ({ username, createdAt, isModerator, isVIP }) => {
  let label = null;
  if (isModerator) {
    label = 'MOD';
  }
  if (isVIP) {
    label = 'VIP';
  }

  const trigger = <span>{username}</span>;
  const content = createdAt && <span>Joined on {format(createdAt, 'DD/MM/YYYY HH:mm')}</span>;

  return (
    <>
      {createdAt ? <Popup inverted trigger={trigger} content={content} /> : trigger}
      {label && <UserLabel text={label} />}
    </>
  );
};

Username.propTypes = {
  username: PropTypes.string.isRequired,
  isModerator: PropTypes.bool,
  isVIP: PropTypes.bool
};

Username.defaultProps = {
  isModerator: false,
  isVIP: false
};

export default Username;
